<template>
    <div class="integral-wrap">
        <div class="btn-box">
            <el-button type="primary" size="small" :plain="selectIndex === 0 ? false : true"
                @click="handleChange(0)">全部</el-button>
            <el-button type="primary" size="small" :plain="selectIndex === 1 ? false : true"
                @click="handleChange(1)">待发货</el-button>
            <el-button type="primary" size="small" :plain="selectIndex === 2 ? false : true"
                @click="handleChange(2)">已发货</el-button>
            <el-button type="primary" size="small" :plain="selectIndex === 3 ? false : true"
                @click="handleChange(3)">已完成</el-button>
        </div>
        <ul class="order-box">
            <li v-for="(item, index) in 10" :key="index">
                <div class="time-box">
                    <span>兑换时间：2021-06-11 11:29:23</span>
                    <span class="state">已发货</span>
                </div>
                <div class="order-info">
                    <div class="left">
                        <img class="img" src="@/assets/images/eSports/绝地求生.png" alt="">
                        <div class="content">
                            <div class="title">
                                绝地求生升级版体验资格8小时
                                00 天 07 时 49 分 59 秒
                            </div>
                            <div class="time">
                                开始时间：2021-06-11 11:29:23
                                结束时间：2021-06-11 11:29:23
                            </div>
                            <div class="integral">
                                999积分
                            </div>
                        </div>
                    </div>
                    <div class="btn-box">
                        <el-button type="primary" plain>登录游戏</el-button>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectIndex: 0,
        }
    },
    methods: {
        handleChange(index) {
            this.selectIndex = index
            console.log(this.selectIndex);
        }
    }
}
</script>

<style scoped lang="scss">
.integral-wrap {
    .btn-box {
        margin-bottom: 10px;
    }

    .order-box {
        height: 430px;
        overflow-y: auto;

        li {
            margin-bottom: 10px;
            border-radius: 6px;
            padding: 0 15px;
            @include item_bg_col();

            .time-box {
                border-bottom: 1px solid #E9E9E9;
                padding: 15px 0 10px 0;
                display: flex;
                justify-content: space-between;

                .state {
                    @include font_col3();
                }
            }

            .order-info {
                display: flex;
                justify-content: space-between;
                padding: 15px 0;

                .left {
                    display: flex;
                    align-items: center;

                    .img {
                        width: 192px;
                        height: 100px;
                        display: block;
                        margin-right: 16px;
                    }

                    .content {
                        display: flex;
                        flex-direction: column;


                        .title {
                            font-size: 20px;
                            @include font_col3();
                        }

                        .time {
                            margin: 15px 0;
                            @include font_col9();
                        }

                        .integral {
                            font-size: 22px;
                            color: #ff3636;
                        }
                    }
                }
            }

        }
    }
}
</style>